.amplify-button--primary {
    height: 51px;
    border-radius: 8px;
    border: 0;
    background-color: #2173FF;

    font-size: 1em;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    color: #FFFFFF;
    font-style: normal;
    line-height: normal;
}

.amplify-input {
    height: 48px;
    font-size: 1em;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
    box-sizing: border-box;

    border-radius: 8px;
    border: 1px solid #BABABA;

    &::placeholder {
        color: rgba(0, 0, 0, 1);
        font-family: "Inter", sans-serif;
        font-weight: 400;
    }
}

.amplify-tabs {
    display: none;
}

.amplify-button--link {
    font-size: 1em;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    color: #2173FF;
    font-style: normal;
    line-height: normal;

    padding-block-start: 17px;
    padding-inline-start: 10px;
    padding-inline-end: 10px;

    &:hover {
        background: inherit;
        text-decoration: underline;
    }
}

[data-amplify-authenticator] {
    --amplify-components-authenticator-container-width-max: 434px;
    --amplify-components-authenticator-modal-background-color: #FAFAFA;
    --amplify-components-authenticator-router-background-color: #FAFAFA;
}

[data-amplify-authenticator] [data-amplify-footer] {
    text-align: right;
}

[data-amplify-authenticator] [data-amplify-form] {
    padding: 10px 0 0;
}

[data-amplify-authenticator] [data-amplify-router] {
    border: 0;
    box-shadow: inherit;
}

@media (max-width: 767px) {
    [data-amplify-authenticator] {
        --amplify-components-authenticator-container-width-max: 90%;
    }

    .amplify-button--primary {
        height: 44px;
        font-size: 0.9em;
    }

    .amplify-input {
        height: 40px;
        font-size: 0.9em;
    }

    .amplify-button--link {
        font-size: 0.9em;
    }

    [data-amplify-authenticator] {
        --amplify-components-authenticator-container-width-max: 90%;
    }
}

@media (max-width: 480px) {
    [data-amplify-authenticator] {
        --amplify-components-authenticator-container-width-max: 95%;
    }

    .amplify-button--primary {
        height: 40px;
        font-size: 0.8em;
    }

    .amplify-input {
        height: 36px;
        font-size: 0.8em;
    }

    .amplify-button--link {
        font-size: 0.8em;
    }
}

/* Add these new styles for better responsiveness */
.MainWrapper {
    padding: 20px;
    box-sizing: border-box;
    max-width: 100%;
}

.TitleWrapper {
    font-size: clamp(1rem, 2.5vw, 1.5rem);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.DynamicTitleAnimatedWordWrapper {
    min-height: 1.5em; /* Adjust based on your font size */
}

.with-aims {
    text-align: center;
}

.FormWrapper {
    width: 40vh; /* Set the width to 40vh */
    max-width: 434px; /* Keep this to ensure it doesn't exceed the original max-width */
    margin: 0 auto;
}

.BoxHeaderImage img {
    max-width: 100%;
    height: auto;
}

@media (max-width: 767px) {
    .TitleWrapper {
        display: none; /* Hide the entire TitleWrapper on mobile */
    }

    .DynamicTitleAnimatedWordWrapper {
        display: none;
    }

    .with-aims {
        margin-top: 1em; /* Add some space above "with AIMS" on mobile */
    }

    .FormTitle {
        font-size: 1.5rem;
    }

    .FormSubtitle {
        font-size: 0.9rem;
    }

    .FormFooter, .Footer {
        font-size: 0.8rem;
    }

    .FormWrapper {
        width: 90%; /* For mobile, use percentage-based width for better responsiveness */
        max-width: 40vh; /* Limit the max-width to 40vh on mobile as well */
    }
}