/* CustomChars.css */

.custom-charts {
  width: 100%;
  margin-bottom: 100px;
  position: relative;
}

.chart-container {
  width: 818px;
  
  left: 0px;
  top: 0px;
  position: absolute;
  display: flex;
  flex-direction: column;
}

.chart-wrapper {
  width: 818px;
  
  left: 0px;
  top: 0px;
  position: absolute;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.inner-chart {
  width: 818px;
  
  background: white;
  border-radius: 10px;
}

.label {
  width: 60.22px;
  height: 24px;
  left: 682.50px;
  top: 33px;
  position: absolute;
}

.title {
  left: 75.28px;
  top: 30px;
  position: absolute;
  color: black;
  font-size: 22px;
  
  font-weight: 500;
  word-wrap: break-word;
}

.subtitle {
  width: 634.83px;
  left: 77.79px;
  top: 65px;
  position: absolute;
  opacity: 0.40;
  color: black;
  font-size: 18px;
  
  font-weight: 400;
  line-height: 20px;
  word-wrap: break-word;
}

.chart-space {
  margin-top: 20px; /* Adjust the spacing as needed */
}
